<template>
  <!-- 三种类型共用一个dom， 通过不同的类型 以及pickUpInAStore 来控制展示内容，   -->
  <div class="submitOrder page-gray">
    <div class="orderCard" v-if="totalAnyData.uiParam.length>0">
      <div class="head van-hairline--bottom">
        <template>
          <div class="left" v-if="!pickUpInAStore">
            <img src="./../../assets/images/order/submitOrder/kddj.png" alt="">
            <span> 快递到家 </span>
          </div>
          <div class="left" v-else>
            <span>到店自提</span>
          </div>

        </template>
        <div class="swtich" v-if="'' !== store">
          <span>门店取货免邮费</span>
          <van-switch  v-model="pickUpInAStore" :size="px2rem(17)"></van-switch>
        </div>
      </div>

      <div class="address van-hairline--bottom">
        <img src="./../../assets/images/order/submitOrder/address_home.png"
             v-if="!pickUpInAStore" class="address_home" alt="">
        <img src="./../../assets/images/order/submitOrder/address_store.png"
             v-if="pickUpInAStore" class="address_store" alt="">

        <div class="content"  v-if="!pickUpInAStore"  @click="$router.push('/address/list?type=choose')">
          <div class="addressInfo">{{ address.province + address.city + address.district + address.address }}</div>
          <div class="userInfo">
            <div class="name">{{ address.receiverName }}</div>
            <div class="phone">{{ address.phone }}</div>
          </div>
        </div>

        <div class="content"  v-if="pickUpInAStore" >
          <div class="addressInfo">{{ store.province+store.city+store.district+store.address }}</div>
        </div>

        <img v-if="!pickUpInAStore" src="./../../assets/images/order/submitOrder/arrow.png" class="arrow"
             alt="">
      </div>

      <div class="goodsList van-hairline--bottom">
        <div v-for="(item,index) in totalAnyData.uiParam" :key="index" :class="{item: true, simple: item.skuType === 2}">
          <div class="top">
            <div class="goodsInfo">
              <van-swipe-cell :right-width="60">
                <div class="goods">
                  <div class="base">
                    <div class="imgBox">
                      <img
                          :src="item.releaseProduct.image"
                          alt=""
                      />
                    </div>
                    <div class="right">
                      <div class="name van-multi-ellipsis--l2">
                        {{ item.releaseProduct.name }}
                      </div>

                      <div class="tagList" style="display: inline-block">
                        <span class="tag" v-show="item.releaseProduct.deliveryMode =='2'">配送到校</span>
                        <span class="tag" v-show="item.releaseProduct.deliveryMode =='1'">快递到家</span>
                      </div>
                      <div style="display: inline-block;margin-left: 10px;">
                        <div v-show="item.releaseProduct.deliverGoodsTemp === '1'"
                             class="deliveryTime">下单{{item.releaseProduct.day}}天内发货</div>
                        <div v-show="item.releaseProduct.deliverGoodsTemp === '2'"
                             class="deliveryTime">预计配送时间：{{ item.releaseProduct.deliverGoodsDate }}</div>
                        <div v-show="item.releaseProduct.deliverGoodsTemp === '3'"
                             class="deliveryTime"
                             v-html="item.releaseProduct.deliverGoodsContent">
                        </div>
                      </div>

                      <div class="price">
                        <div class="money">
                          <span>¥</span>
                          <span>{{ item.price.toFixed(2)  }}</span>
                        </div>
                        <div class="number">
                          x{{ item.totalNum }}
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </van-swipe-cell>

              <div class="skuInfo">
                <!-- 校服包才显示 -->
                <div class="title">
                  <span>商品规格:</span>
                  <!--                    <div class="sku" @click="changeSku(item)">修改规格 ></div>-->
                </div>

                <div class="skuItem" v-for="(sku,temp) in item.skuProductData" :key="temp">
                  <!-- 校服包才显示 -->
                  <span class="name"> {{ sku.name }}  </span>
                  <span class="amount">x{{ sku.num * item.totalNum }}</span>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>

      <div class="orderInfo">
        <div>
          <span class="key" >商品小计</span>
          <span class="value price">
                        <i>¥</i>
                        <i>{{ totalAnyData.totalPrice }}</i>
                    </span>
        </div>
        <div v-if="!pickUpInAStore && totalAnyData.postage>=0">
          <span class="key" >邮费</span>
          <span class="value price">
                        <i>¥</i>
                        <i>{{ totalAnyData.postage }}</i>
          </span>
        </div>
      </div>
    </div>

    <div class="orderCard" v-if="totalReserveData.uiParam.length>0">
      <div class="head van-hairline--bottom">
        <template>
          <div class="left">
            <img src="./../../assets/images/order/submitOrder/kddx.png" alt="">
            <span>配送到校</span>
          </div>
        </template>
      </div>

      <div class="address van-hairline--bottom">
        <img src="./../../assets/images/order/submitOrder/address_school.png"
             v-if="!pickUpInAStore" class="address_home" alt="">

        <div class="content">
          <div class="addressInfo">{{ studentInfo.school + studentInfo.grade + studentInfo.classn }}</div>
          <div class="userInfo" style="color: #999999">
            <div class="name">{{ studentInfo.receiverName?studentInfo.receiverName:'学生姓名' }}</div>
            <div class="phone">{{ studentInfo.phone?studentInfo.phone:'手机号码' }}</div>
          </div>
        </div>
        <div style="display: contents" @click="showLimitedTimeStudentInfo2">
          <span style="    margin-right: 10px;font-size: 12px;color: #FE3726;">完善配送信息</span>
          <img src="./../../assets/images/order/submitOrder/arrow.png" class="arrow"
               alt="">
        </div>
      </div>

      <div class="goodsList van-hairline--bottom">
          <div v-for="(item,index) in totalReserveData.uiParam" :key="index" :class="{item: true, simple: item.skuType === 2}">
            <div class="top">
              <div class="goodsInfo">
                <van-swipe-cell :right-width="60">
                  <div class="goods">
                    <div class="base">
                      <div class="imgBox">
                        <img
                            :src="item.releaseProduct.image"
                            alt=""
                        />
                      </div>
                      <div class="right">
                        <div class="name van-multi-ellipsis--l2">
                          {{ item.releaseProduct.name }}
                        </div>

                        <div class="tagList" style="display: inline-block">
                          <span class="tag" v-show="item.releaseProduct.deliveryMode =='2'">配送到校</span>
                          <span class="tag" v-show="item.releaseProduct.deliveryMode =='1'">快递到家</span>
                        </div>
                        <div style="display: inline-block;margin-left: 10px;">
                          <div v-show="item.releaseProduct.deliverGoodsTemp === '1'"
                               class="deliveryTime">下单{{item.releaseProduct.day}}天内发货</div>
                          <div v-show="item.releaseProduct.deliverGoodsTemp === '2'"
                               class="deliveryTime">预计配送时间：{{ item.releaseProduct.deliverGoodsDate }}</div>
                          <div v-show="item.releaseProduct.deliverGoodsTemp === '3'"
                               class="deliveryTime"
                               v-html="item.releaseProduct.deliverGoodsContent">
                          </div>
                        </div>

                        <div class="price">
                          <div class="money">
                            <span>¥</span>
                            <span>{{ item.price  }}</span>
                          </div>
                          <div class="number">
                            x{{ item.totalNum }}
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </van-swipe-cell>

                <div class="skuInfo">
                  <!-- 校服包才显示 -->
                  <div class="title">
                    <span>商品规格:</span>
                    <!--                    <div class="sku" @click="changeSku(item)">修改规格 ></div>-->
                  </div>

                  <div class="skuItem" v-for="(sku,temp) in item.skuProductData" :key="temp">
                    <!-- 校服包才显示 -->
                    <span class="name"> {{ sku.name }}  </span>
                    <span class="amount">x{{ sku.num * item.totalNum }}</span>
                  </div>
                </div>

              </div>
            </div>

          </div>
      </div>

      <div class="orderInfo">
        <div>
          <span class="key">商品小计</span>
          <span class="value price">
                        <i>¥</i>
                        <i>{{totalReserveData.totalPrice}}</i>
                    </span>
        </div>
      </div>
    </div>
    <div class="submitBtn" v-if="totalAnyData.postage && totalAnyData.postage<0">
      <span class="count">共计{{ number }}件</span>
      <span class="label">商品未配置该地区的配送，请<a :href="'tel'+afterSalesSet.phone">《联系客服》</a>添加</span>
    </div>
    <div class="submitBtn" v-else>
      <span class="count">共计{{ number }}件</span>
      <span class="label">付款金额:</span>
      <div>
        <span class="unit">¥</span>
        <span class="price">{{ total }}</span>
      </div>
      <van-button round
                  v-if="totalReserveFlag"
                  :type="seconds > 0 ? 'info' : 'primary'"
                  size="small"
                  :color="seconds > 0 ? '#dcdcdc':'#07c160'"
                  :style="{padding: '0 ' + px2rem(16)}"
                  :disabled="seconds > 0"
                  @click="dialog=true">
        {{ seconds > 0 ? `请等待（0${seconds}s）` : '提交订单' }}
      </van-button>
      <van-button round
                  v-else
                  type="info"
                  size="small"
                  :color="'#dcdcdc'"
                  :style="{padding: '0 ' + px2rem(16)}"
                  @click="showLimitedTimeStudentInfo">
        {{'提交订单' }}
      </van-button>
    </div>


    <van-popup v-model="dialog" round :style="{ width: '80vw' }">
      <div class="dialog">
        <div v-if="'' !== store">
          <div class="title">门店信息</div>
          <div class="item">
            <div class="key">自提点：</div>
            <div class="value">{{ store.province+store.city+store.district+store.address }}</div>
          </div>
        </div>
        <div v-else-if="totalAnyData.uiParam.length>0">
          <div class="title">请您核对收货信息</div>
          <div class="item">
            <div class="key">收件人：</div>
            <div class="value">{{ address.receiverName }}</div>
          </div>
          <div class="item">
            <div class="key">电话：</div>
            <div class="value">{{ address.phone }}</div>
          </div>
          <div class="item">
            <div class="key">地址：</div>
            <div class="value">{{ address.province + address.city + address.district + address.address }}</div>
          </div>
          <div class="tips">如有问题，请点击取消后返回上一步进行修改资料。请务必填写详细的家庭地址，切勿填写学校地址作为收货地址。</div>
        </div>
         <div v-else>
           <div class="title">请您核对</div>
           <div class="item">
             <div class="key">学生：</div>
             <div class="value">{{ studentInfo.receiverName }}</div>
           </div>
           <div class="item">
             <div class="key">学校：</div>
             <div class="value">{{ studentInfo.school + studentInfo.grade + studentInfo.classn }}</div>
           </div>
           <div class="tips">限时购商品将会配送到学校。</div>
         </div>

        <div class="buttonGroup">
          <div class="btnItem" @click="dialog = false">取消</div>
          <div class="btnItem primary" @click="submitOrder">确定</div>
        </div>
      </div>
    </van-popup>

    <global-web-socket uri="/mall/ws/info" @setload="setLoad"></global-web-socket>
    <student-register ref="studentRegister" @input="success"></student-register>
  </div>
</template>

<script>
import GlobalWebSocket from '@/components/WebSocket/GlobalWebSocket'
import Vue from "vue";
import {mapState} from 'vuex';
import { Decimal } from 'decimal.js'
import { debounce } from 'lodash'
import {validatenull} from "../../util/util";
import StudentRegister from "../../components/student/studentRegister.vue";
export default {
  name: 'Submitorder',
  components:{
    StudentRegister,
    GlobalWebSocket
  },
  data() {
    return {
      dialog: false,
      pickUpInAStore: false,// 门店自提
      load:false,//加载
      store:'',//门店
      //售后单
      afterSalesOrderId:"",
      //配送方式 1 快递到家  2 配送到校
      deliveryMode:"",
      orderId:"",//订单id
      address: {
        address: '',
        receiverName: '',
        phone: ''
      },
      pullPayment:true,//拉取支付
      error:false,
      totalAnyData: {
        uiParam:[],
      },
      totalReserveData:{
        uiParam:[],
      },
      list: [{
        logisticsType: 1, // 物流方式 1 快递 （可以切换自提）， 2 配送到校 （不可切换自提）
      }, {
        logisticsType: 2, // 物流方式 1 快递 （可以切换自提）， 2 配送到校 （不可切换自提）
      }],
      //是否主动查询订单信息
      proactivelyQueryingOrder:false,
      //倒计时12秒
      seconds: 0,
      //限时购支付条件
      totalReserveFlag:true,
      alertShow:false,
    }
  },
  computed:{
    ...mapState(['afterSalesSet','studentInfo']),
    // eslint-disable-next-line vue/return-in-computed-property
    number() {
      let number = 0;
      if(this.totalAnyData.uiParam && this.totalAnyData.uiParam.length>0){
        this.totalAnyData.uiParam.forEach(item=>{
          //随时购商品总数
          number += item.totalNum
        })
      }

      if(this.totalReserveData.uiParam && this.totalReserveData.uiParam.length>0){
        this.totalReserveData.uiParam.forEach(item=>{
          //限时购商品总数
          number += item.totalNum
        })
      }
      return number;
    },
    total() {
      // 这里还需要合计每个规格的价格吧， 懒得造数据了

      let totalPrice = 0;

      if(this.totalAnyData.uiParam && this.totalAnyData.uiParam.length>0){
        if(!this.pickUpInAStore){
          totalPrice = new Decimal(totalPrice)
              .add(new Decimal(this.totalAnyData.totalPrice))
              .add(new Decimal(this.totalAnyData.postage)).toNumber();
          // totalPrice +=this.totalAnyData.totalPrice+this.totalAnyData.postage
        }else{
          totalPrice = new Decimal(totalPrice).add(new Decimal(this.totalAnyData.totalPrice)).toNumber();
          // totalPrice +=this.totalAnyData.totalPrice
        }
      }

      if(this.totalReserveData.uiParam && this.totalReserveData.uiParam.length>0){
        totalPrice = new Decimal(totalPrice).add(new Decimal(this.totalReserveData.totalPrice)).toNumber();
        // totalPrice += this.totalReserveData.totalPrice;
      }

      return totalPrice;
    }
  },
  watch:{
    proactivelyQueryingOrder:{
      handler(val) {
        if (val) {
          //定时器
          let timer = setInterval(() => {
            //查询订单
            this.$http.get(`/mall/h5_mall_order/queryOrderIsExist/${this.orderId}`).then(res => {
              //订单存在可以支付
              if(res.data && this.pullPayment){
                //存在订单去支付
                this.toPay();
              }else if(!this.error && this.pullPayment){
                //没有报错，也没有支付
                this.$toast("订单生成失败，请重新选择商品");
                this.$router.go(-1)
              }
            });
            this.proactivelyQueryingOrder = false;
            //清除定时器
            clearInterval(timer);
          },12000)
        }
      },
      immediate: true,
    }
  },
  methods: {
    setLoad(msgEvent){
      if(msgEvent.data !=='orderSuccess'){
        this.error = true;
        this.$dialog.alert({
          message: msgEvent.data,
        }).then(() => {
          this.$router.back()
        });
        this.dialog = false;
        return ;
      }

      this.toPay();
    },
    toPay(){
      if(this.pullPayment){
        //等待300毫秒
        let timeout = setTimeout(()=>{
          this.load = false;
          //获取后台数据
          this.$http.get(`/pay/goods/jsApiPay/${Vue.prototype.deptId}`,{
            params: {
              orderId: this.orderId,
            }
          }).then(data => {
            window.WeixinJSBridge.invoke(
                'getBrandWCPayRequest', data.data,
                (res)=>{
                  this.pullPayment = false;
                  if(res.err_msg === "get_brand_wcpay_request:ok" ){
                    this.$toast.success('支付成功');
                    this.dialog = false;
                    let timer = setInterval(()=>{
                      this.$toast.clear();
                      clearInterval(timer);
                    },2000)

                    this.$router.replace('/order/paySuccess')
                    // 使用以上方式判断前端返回,微信团队郑重提示：
                    //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                  }else{
                    this.$toast("支付失败");
                    this.$router.push('/order/detail/' + this.orderId)
                  }

                });
          });
          clearTimeout(timeout);
        },300)
      }
    },
    submitOrder: debounce(
        function() {
          this.dialog = false;
          let totalAnyData = this.totalAnyData.uiParam;
          let totalReserveData = this.totalReserveData.uiParam;
          let orderCreateParamDto = {
            pickUpInStore:this.pickUpInAStore,
            receiverId:this.address.id===undefined?null:this.address.id,
            memo:"",
            ordertype:1,
            afterSalesOrderId:this.afterSalesOrderId,
            gradeId : this.studentInfo.gradeId,
            school : this.studentInfo.school,
            grade : this.studentInfo.grade,
            classnId : this.studentInfo.classId,
            classn : this.studentInfo.classn,
            name : this.studentInfo.receiverName,
            phone : ''===this.store && '' !== this.address.phone?this.address.phone:this.studentInfo.phone,
            storeId:''===this.store?null:this.store.storeId,
            deptId : `${Vue.prototype.deptId}`,
          }
          this.$http.post(this.baseMallUrl + `/h5_mall_order/buyAtAnyPlaceAnOrder`, {totalAnyData:totalAnyData,totalReserveData:totalReserveData,orderCreateParamDto:orderCreateParamDto}, {
            emulateJSON: true
          }).then(response => {
            this.orderId = response.data;
            this.proactivelyQueryingOrder = true;
            this.seconds = 12;

            this.timer = setInterval(() => {
              this.seconds = this.seconds - 1;

              if (this.seconds === 0) {
                clearInterval(this.timer)
              }
            }, 1000)

          })
        },
        6000,
        {
          leading: true, // 在延迟开始前立即调用事件
          trailing: false, // 在延时结束后不调用,保证事件只被触发一次
        },
    ),
    init(){
      let address = JSON.parse(localStorage.getItem('selectAddress'));
      let reserveProductData =  JSON.parse(localStorage.getItem('reserveProductData'));
      let anyProductData = JSON.parse(localStorage.getItem('anyProductData'));
      let store = JSON.parse(localStorage.getItem('store'));
      this.afterSalesOrderId = localStorage.getItem('afterSalesOrderId');
      this.deliveryMode = localStorage.getItem('deliveryMode');


      if(store){//门店购买逻辑
        this.pickUpInAStore = true;
        this.store = store;
        //计算门店商品费用
        this.$http.post(this.baseProductUrl + `/h5_product/calcStoreProductTotalFee`, anyProductData, {
          emulateJSON: true
        }).then(response => {
          this.totalAnyData = response.data;
        })
        return ;
      }

      // if(anyProductData.length > 0 && !address){
      //   this.$router.push({path: '/address/list?type=choose'})
      //   return ;
      // }

      // 计算随时购费用
      if (anyProductData.length > 0) {
        if(address){
          this.$http.post(this.baseProductUrl + `/h5_product/calcPostageOnBasedAddressAndProductInfo/${address.id}`, anyProductData, {
            emulateJSON: true
          }).then(response => {
            this.totalAnyData = response.data;
            if(this.totalAnyData.postage && this.totalAnyData.postage<0){
              this.$dialog.alert({
                message: "商品未配置该区域的配送，请联系客服配置",
              })
            }
          }).catch(()=> {
            const timer = setInterval(() => {
              clearInterval(timer);
              this.$router.go(-1)
            }, 2000);
          })
        }else {
          //获取默认快递地址
          this.$http.get(this.baseMallUrl + `/h5_mall/getUserDefaultAddress`).then(res => {
            this.address = res.data;
            if(null == this.address){
              this.$router.push({path: '/address/list?type=choose'})
                return ;
            }else{
              this.$http.post(this.baseProductUrl + `/h5_product/calcPostageOnBasedAddressAndProductInfo/${this.address.id}`, anyProductData, {
                emulateJSON: true
              }).then(response => {
                this.totalAnyData = response.data;
                if(this.totalAnyData.postage && this.totalAnyData.postage<0){
                  this.$dialog.alert({
                    message: "商品未配置该区域的配送，请联系客服配置",
                  })
                }
              }).catch(()=> {
                const timer = setInterval(() => {
                  clearInterval(timer);
                  this.$router.go(-1)
                }, 2000);
              })
            }

          });
        }
      }

      //计算限时购费用
      if(reserveProductData.length > 0){
        this.$http.post(this.baseProductUrl + `/h5_product/calcReserveProductCost`, reserveProductData, {
          emulateJSON: true
        }).then(response => {
          //  reserveProductData: [],
          this.totalReserveData = response.data;
        })
        //判断学生信息是否完整
        if(validatenull(this.studentInfo.receiverName)
            || validatenull(this.studentInfo.grade)
            || validatenull(this.studentInfo.classn)
            || validatenull(this.studentInfo.phone)){
          this.totalReserveFlag = false
        }else{
          this.totalReserveFlag = true
        }
      }

      if (address) {
        try {
          // TODO
          this.address = address;
          localStorage.removeItem('selectAddress')
        } catch (error) {
          console.log(error)
        }
      }

    },
    //填写限时购学生数据
    showLimitedTimeStudentInfo(){
      this.$dialog.alert({
        message: '您未完善校服配送信息，有助于校服精准发放和售后',
        theme: 'round-button',
        confirmButtonText:"去填写",
        confirmButtonColor:"#FF5028"
      }).then(() => {
        this.$nextTick(() => {
          this.$refs.studentRegister.init()
        })
      });
    },//填写限时购学生数据
    showLimitedTimeStudentInfo2(){
      this.$refs.studentRegister.init()
    },
    success(){
      this.totalReserveFlag = true;
      this.$toast.success('编辑成功');
    }
  },
  mounted() {
    this.init();
  },
  activated() {
    this.init();
  }
};
</script>

<style lang="less" scoped>

    .item {
      background: #ffffff;
      border-radius: 8px;
      margin-bottom: 15px;
      &.simple {
        .skuItem {
          margin-bottom: 10px;
        }
      }
      div.top {
        display: flex;
      }
      .radio {

        margin: 0 14px 0 0;

        > img {
          height: 19px;
          width: 19px;
        }
      }
      .goodsInfo {
        width: 100%;
        .goods {
          margin-top: 17px;
          display: flex;
          padding-right: 7px;
          align-items: center;
          padding-left: 13px;
          padding-bottom: 5px;
          .base {
            display: flex;
          }
          .imgBox {
            width: 95px;
            height: 80px;
            border-radius: 5px;
            min-width: 95px;
            margin-right: 14px;
            position: relative;
            .notAvailable {
              position: absolute;
              width: 95px;
              height: 80px;
              border-radius: 5px;
              background-color: rgba(255,255,255,.4);
              top: 0;
              left: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              .circle {
                width: 41px;
                height: 41px;
                background: rgba(0,0,0,.5);
                border-radius: 50%;

                line-height: 41px;
                text-align: center;

                font-size: 13px;
                color: rgba(255,255,255,.9);
              }
            }
            img {
              width: 95px;
              height: 80px;
              border-radius: 5px;

            }
          }

          .right {
            padding-top: 3px;
            // height: 80px;
            flex: 1;
            .name {
              line-height: 14px;
              font-size: 12px;
              font-weight: 400;
              color: #333333;

              .ys {
                width: 32px;
                background: linear-gradient(264deg, #f58130, #ef2514);
                border-radius: 2px;

                font-size: 12px;
                font-weight: 500;
                color: #F4F4F4;
                line-height: 12px;
                text-align: center;
                padding: 2px 0;
                display: inline-block;
                margin-right: 3px;

              }
            }

            .tagList {
              display: flex;
              margin: 7px 0 5px 0;
              .tag {
                outline: 1px solid #F02336;
                border-radius: 2px;
                line-height: 10px;
                font-size: 10px;
                font-weight: 400;
                color: #F02336;
                padding: 2px 7px;
              }
            }
            .deliveryTime {
              margin: 0px 0 5px 0;
              font-size: 14px;
              color: #F3B66A;
            }

            .price {
              display: flex;
              align-items: center;
              justify-content: space-between;
              height: 16px;
              .money {
                font-size: 0;
                span {
                  font-size: 12px;
                  font-weight: 500;
                  color: #D53A26;
                  margin-right: 3px;
                  line-height: 15px;

                  &:last-child {
                    font-size: 15px;
                    font-weight: bold;
                  }
                }
              }
              .number {
                padding: 4px 4px;
                background: #FFFFFF;
                border: 1px solid #DCDCDC;
                border-radius: 4px;
                line-height: 12px;
                font-size: 12px;
                font-weight: 400;
                color: #333333;
              }

              .sku {
                background: #EEEEEE;
                border-radius: 2px;
                padding: 3px 6px;

                line-height: 11px;
                font-size: 10px;
                font-weight: 400;
                color: #999999;
              }
            }
          }
        }

        .skuInfo {
          .title {
            padding: 25px 0 0 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            span {
              line-height: 12px;
              font-size: 12px;
              font-weight: 400;
              color: #333;
            }
            .sku {
              line-height: 12px;
              font-size: 12px;
              font-weight: 400;
              color: #D53A26;
            }
            img {
              width: 9px;
              height: 5px;
              transition: all .2s;
              &.close {
                transform: rotateZ(180deg);
              }
            }
          }

          .skuItem {
            display: flex;
            margin-top: 10px;
            margin-bottom: 10px;
            padding: 5px;
            background: #F9F9F9;
            border-radius: 3px;
            font-size: 12px;
            color: #666;
            .name {
              width: 80%;
              display: inline-block;
            }
            .amount{
              text-align: right;
              width: 20%;
              display: inline-block;
            }
          }
        }

        .handle {
          display: flex;
          justify-content: space-between;
          padding: 13px 17px 12px 0;
          .money {
            span {
              font-weight: 500;
              &:first-child {
                font-size: 10px;
                color: #333333;
              }
              &:nth-child(2) {
                font-size: 12px;
                color: #D53A26;
                margin: 0 3px;
              }
              &:nth-child(3) {
                font-size: 15px;
                color: #D53A26;
              }
            }
          }

          .count {
            span {
              line-height: 10px;
              font-size: 10px;
              font-weight: 500;
              color: #333333;

              &:last-child {
                color: #D53A26;
                margin-left: 3px;
              }
            }
          }
        }
      }
      .timeLimit {
        font-size: 14px;
        font-weight: 500;
        color: #D53A26;
        span {
          font-size: 12px;
          background: #F9EDEB;
          padding: 3px;
        }
        .van-count-down {
          display: inline-block;
          font-size: 14px;
          font-weight: 500;
          color: #D53A26;
          margin-left: 5px;
        }
      }

      .skuInfo, .handle {
        padding-right: 13px;
        padding-left: 13px !important;
      }
      div.bottom {
        border-top: 1px solid #eee;
        padding: 7px 45px 10px 45px;
        p {
          line-height: 19px;

          .key {
            font-size: 10px;
            font-weight: 500;
            color: #333333;
            display: inline-block;
            width: 40px + 28px;
          }

          .value {
            font-size: 10px;
            font-weight: 400;
            color: #999999;
          }
        }

      }
    }

.van-hairline--bottom {
  &::after {
    border-color: #EEEDEC !important;
  }
}

.orderCard {
  margin: 10px;
  background: #FFFFFF;
  border-radius: 8px;

  .head {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 18px;

    .left {
      display: flex;
      align-items: center;

      img {
        width: 21px;
        min-width: 21px;
        margin-right: 7px;
      }

      span {
        line-height: 15px;
        font-size: 15px;
        font-weight: 500;
        color: #212121;
      }
    }

    .swtich {
      display: flex;
      align-items: center;

      span {
        margin-right: 10px;
        line-height: 12px;
        font-size: 12px;
        font-weight: 400;
        color: #212121;
      }
    }
  }

  .address {
    padding: 10px 18px 17px 18px;
    display: flex;
    align-items: center;

    .address_home, .address_store {
      width: 12px;
      min-width: 12px;
      margin-right: 12px;
    }

    .address_school {
      width: 15px;
      min-width: 15px;
      margin-right: 8px;
    }

    .content {
      flex: 1;

      .addressInfo {
        line-height: 19px;
        font-size: 13px;
        color: #212121;
        margin-bottom: 10px;
      }

      .userInfo {
        display: flex;
        align-items: center;

        div {
          line-height: 12px;
          font-size: 12px;
          color: #999999;
          margin-right: 21px;
        }
      }
    }

    .arrow {
      height: 13px;
      width: 8px;
      min-width: 8px;
    }
  }


  .goodsList {
    padding: 0 12px 0 18px;

    .goodsItem {
      padding: 14px 0 18px 0;
      display: flex;
      align-items: flex-start;

      &:last-child {
        &::after {
          border: none;
        }
      }

      .goodsEspImg {
        width: 95px;
        min-width: 95px;
        height: 80px;
        margin-right: 14px;
        border-radius: 5px;
      }

      .goodsContent {
        width: 100%;
        .goodsName {
          line-height: 14px;
          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #333333;
        }

        .sku {
          background: #EEEEEE;
          border-radius: 2px;
          margin: 10px 0 3px 0;
          font-size: 10px;
          color: #666666;
          line-height: 10px;
          padding: 5px 10px;
          display: inline-block;
        }

        .price {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 16px;
          .money {
            font-size: 0;
            span {
              font-size: 12px;
              font-weight: 500;
              color: #D53A26;
              margin-right: 3px;
              line-height: 15px;

              &:last-child {
                font-size: 15px;
                font-weight: bold;
              }
            }
          }
          .number {
            padding: 4px 4px;
            background: #FFFFFF;
            border: 1px solid #DCDCDC;
            border-radius: 4px;
            line-height: 12px;
            font-size: 12px;
            font-weight: 400;
            color: #333333;
          }

          .sku {
            background: #EEEEEE;
            border-radius: 2px;
            padding: 3px 6px;

            line-height: 11px;
            font-size: 10px;
            font-weight: 400;
            color: #999999;
          }
        }
        .time {
          line-height: 12px;
          font-size: 12px;
          color: #E7AC58;
          margin-top: 10px;
        }
      }
    }
  }

  .orderInfo {
    padding: 7px 19px;

    > div {
      padding: 7px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .key {
        line-height: 12px;
        font-size: 12px;
        color: #333333;
      }

      .value {
        line-height: 12px;
        font-size: 12px;
        color: #333333;

        &.price {
          width: 46px + 26px - 19px;
          text-align: left;

          i {
            font-style: normal;
            font-weight: 500;

            &:first-child {
              font-size: 10px;
              margin-right: 2px;
            }

            &:last-child {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

.submitOrder {
  padding-bottom: 60px;
}

.submitBtn {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: #fff;
  padding: 0 18px;
  position: absolute;
  bottom: 0;
  width: 100%;

  .count {
    line-height: 12px;
    font-size: 12px;
    color: #999999;
    margin-right: 9px;
  }

  .label {
    font-size: 12px;
    color: #212121;
    margin-right: 2px;
  }

  .unit {
    font-size: 12px;
    color: #D53A26;
    margin-right: 2px;
  }

  .price {
    font-size: 15px;
    font-weight: bold;
    color: #D53A26;
    margin-right: 14px;
  }
}


.dialog {
  padding: 27px 26px;
  background: #fff;

  .title {
    line-height: 20px;
    font-size: 20px;
    font-weight: bold;
    color: #212121;
    margin-bottom: 24px;
  }

  .item {
    display: flex;
    margin: 3px 0;

    div {
      font-size: 12px;
      color: #666666;
      line-height: 20px;

      &.key {
        white-space: nowrap;
      }
    }
  }


  .tips {
    line-height: 15px;
    font-size: 10px;
    font-weight: 400;
    color: #FF0000;
    margin: 16px 0 13px 0;
  }

  .buttonGroup {
    display: flex;
    justify-content: flex-end;

    .btnItem {
      width: 65px;
      height: 29px;
      background: #FFFFFF;
      border: 1px solid #666666;
      border-radius: 5px;
      text-align: center;
      line-height: 26px;

      font-size: 15px;
      font-weight: 400;
      color: #666666;

      &.primary {
        border-color: #FF553F;
        background-color: #FF553F;
        color: #fff;
        margin-left: 16px;
      }
    }
  }
}


</style>
