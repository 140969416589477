<template>
  <div class="page-gray" style="padding-top: 1px">
    <van-action-sheet
        v-model="show"
        closeable
        close-icon="close"
        title="完善信息"
        :close-on-click-overlay="false"
        style="min-height: 70vh">

      <van-row type="flex" justify="center">
        <van-col span="22">
          <div class="info">
              <div class="info-title">
                <img  src="@/assets/images/student/tips.png" style="width: 4%">
                请您准确填写以下信息，有助于校服精准发放
              </div>
              <div class="info-content">
                如果您对以下收集的个人信息使用规则有疑问，请查看<a>《用户注册协议》</a>、<a>《儿童隐私政策》</a>及<a>《隐私政策》</a>
              </div>
          </div>
        </van-col>
      </van-row>

      <van-cell-group>
        <template slot="title">
          <span  style="color: #000;font-size: 16px;font-weight: bold">配送到校信息</span>
        </template>
        <van-field class="form-item " readonly :value="form.school" label="学校名称"
                   placeholder="请选择所在学校"/>
        <van-field class="form-item " readonly :value="form.gradeName" @click="gradePicker = true" label="年级"
                   placeholder="请选择所在年级" right-icon="arrow-down"/>
        <van-field class="form-item " readonly :value="form.className" @click="classPicker = true" label="班级"
                   placeholder="请选择所在班级" right-icon="arrow-down"/>
        <van-field class="form-item " v-model="form.name" label="学生姓名" placeholder="请输入学生姓名"/>
        <van-field class="form-item " type="tel" v-model="form.phone" label="家长电话" placeholder="请输入手机号码"/>
        <div style="margin: 16px;">
          <van-button round block color="#FF5028" type="info" @click="submit" native-type="submit">提交</van-button>
        </div>
      </van-cell-group>

    </van-action-sheet>

    <van-popup v-model="gradePicker" round position="bottom">
      <van-picker
          show-toolbar
          :columns="gradeList"
          title="选择年级"
          @cancel="gradePicker = false"
          @confirm="onGradeConfirm"
      />
    </van-popup>

    <van-popup v-model="classPicker" round position="bottom">
      <van-picker
          show-toolbar
          :columns="!form.grade ? [] : clastsList"
          title="选择班级"
          @cancel="classPicker = false"
          @confirm="onClassConfirm"
      />
    </van-popup>
  </div>

</template>

<script>
import {mapGetters,mapMutations} from 'vuex'
import Vue from "vue";
import {validatenull} from "../../util/util";
export default {
  name: 'studentRegister',
  data() {
    return {
      show: false,
      form:{},
      gradePicker: false,
      gradeList: [],
      classPicker: false,
      clastsList: [],
    }
  },
  methods:{
    ...mapGetters(['getStudentInfo']),
    ...mapMutations(['setStudentInfo']),
    init(){
      this.show = true;
      let studentInfo = this.getStudentInfo();
      // TODO send Server
      this.$http.get(this.baseMallUrl + `/h5_mall/queryStudentInfo/${studentInfo.id}`).then(res => {
        let studentInfo = res.data;
        this.form = {
          photo: studentInfo.photo,
          name: studentInfo.receiverName,
          grade: studentInfo.gradeId,
          classn: studentInfo.classId,
          gradeName: studentInfo.grade,
          className: studentInfo.classn,
          studentId: studentInfo.id,
          sex: studentInfo.sex,
          phone: studentInfo.phone,
          schoolId:studentInfo.schoolid,
          school: studentInfo.school,
        }
      })
      this.$http.get(this.baseMallUrl + `/h5_mall/queryGradeBySchoolId/${studentInfo.schoolid}`).then(res => {
        let gradeList = res.data;
        let arrGrade = [];
        for (let index in gradeList) {
          let grade = gradeList[index];
          arrGrade.push({text: grade.name, value: grade.id});
        }
        this.gradeList = arrGrade;
      })
      if(!validatenull(studentInfo.gradeId)){
        this.$http.get(this.baseMallUrl + `/h5_mall/queryClassByGradeId/${studentInfo.gradeId}`).then(res => {
          let classList = res.data;
          let arrClassn = [];
          for (let index in classList) {
            let classn = classList[index];
            arrClassn.push({text: classn.name, value: classn.id});
          }
          this.clastsList = arrClassn;
        })
      }
    },
    onGradeConfirm(item) {
      // item => { text: '高中一年级', value: 1}
      this.form.grade = item.value;
      this.form.gradeName = item.text;
      this.gradePicker = false
      this.$http.get(this.baseMallUrl + `/h5_mall/queryClassByGradeId/${item.value}`).then(res => {
        let classList = res.data;
        let arrClassn = [];
        for (let index in classList) {
          let classn = classList[index];
          arrClassn.push({text: classn.name, value: classn.id});
        }
        this.clastsList = arrClassn;
        this.form.classn = '';
        this.form.className = '';
      })
    },
    onClassConfirm(item) {
      this.form.classn = item.value;
      this.form.className = item.text;
      this.classPicker = false
    },
    submit() {

      if ( !this.form.name) {
        this.$toast('请输入学生姓名');
        return;
      }
      if ( this.form.name.length > 8) {
        this.$toast('学生姓名不能超过8个字符');
        return;
      }
      let nameReg = /[`~!@#$^\-&*()=|{}':;',\\[\].<>/?~！@#￥……&*（）——|{}【】'；：""'。，、？\s]/g;
      if ( nameReg.test(this.form.name)) {
        this.$toast('请输入正确的姓名');
        return;
      }
      if (!this.form.grade) {
        this.$toast('请选择所在年级');
        return;
      }

      if (!this.form.classn) {
        this.$toast('请选择所在班级');
        return;
      }
      if (!this.form.phone) {
        this.$toast('请输入手机号码');
        return;
      }
      let reg = /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/;
      if (!reg.test(this.form.phone)) {
        this.$toast('请输入正确的手机号码');
        return;
      }

      let form = this.form;
      form.userId = Vue.prototype.userId;
      // TODO Send Server
      this.$http.post(this.baseMallUrl + `/h5_mall/updateStudentInfo`, form, {emulateJSON: true}).then(() => {
        //添加默认学生
        this.$http.get(this.baseMallUrl + `/h5_mall/queryStudentInfo/${this.form.studentId}`).then(res => {
          this.setStudentInfo(res.data);
          this.show=false;
          this.$emit('input')
        })
      })

    },
  }
}
</script>

<style scoped lang="less">
.info{
  border-radius: 5px;
  background-color: #FFF5E1;
  padding: 10px;
}
.info-title{
  color: #FF8C00;
  font-size: 11px;
  line-height: 20px;
}
.info-content{
  font-size: 11px;
  line-height: 20px;
  a{
    color: #FF8C00;
    text-decoration: underline;
  }
}

</style>
